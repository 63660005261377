import * as imageSDK from 'image-client-api/dist/imageClientSDK';

export interface MediaGalleryItem {
  fileName: string;
  relativeUri: string;
  width: number;
  height: number;
}

export const IMAGE_PIXEL_DENSITY_FACTOR = 1.5;

export const getImageUrl = (
  media: MediaGalleryItem,
  imageDimensions: { width?: number; height?: number },
) => {
  const pixelDensityFactor = getDesiredPixelDensityFactor();
  return media
    ? imageSDK.getScaleToFillImageURL(
        media.relativeUri,
        media.width,
        media.height,
        imageDimensions.width * pixelDensityFactor,
        imageDimensions.height * pixelDensityFactor,
      )
    : null;
};

const getDesiredPixelDensityFactor = () => {
  return Math.max(
    Math.floor(getDevicePixelRatio() / IMAGE_PIXEL_DENSITY_FACTOR),
    1,
  );
};

export const getDevicePixelRatio = () =>
  (typeof window !== 'undefined' && window.devicePixelRatio) || 1;
